import React, { useState } from 'react'
import {
    PixelRatio,
    Platform,
    StyleProp,
    StyleSheet,
    Text,
    TextProps,
    TextStyle,
} from 'react-native'
import { BuilderTheme } from '../ThemeProvider/themes'
import { useTheme } from '../ThemeProvider/ThemeProvider'

export { TextProps }

export interface TypographyProps extends TextProps {
    variant?: TextVariant
    component?: SemanticComponent // Defines semantic HTML element
    style?: StyleProp<TextStyle>
    children: string | string[] | number | number[]
}

export const Typography = (props: TypographyProps) => {
    const [platform] = useState(Platform.OS)

    const { theme } = useTheme();
    const styles = createStyles(theme);
    
    const {
        variant = 'base',
        style = {},
        component = 'p',
        numberOfLines,
        ...otherProps
    } = props

    if (platform === 'web') {
        const Component = component as any

        otherProps['data-test-id'] = otherProps.testID

        delete otherProps.testID

        return (
            <Component
                style={StyleSheet.flatten([
                    webifySizes(styles[variant]),
                    {
                        fontFamily: 'Inter',
                        cursor:
                            props.onPress !== undefined ? 'pointer' : 'initial',
                    },
                    numberOfLines && {
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: numberOfLines,
                    },
                    webifySizes(arrayToDict(style)),
                ])}
                {...otherProps}
            >
                {props?.children}
            </Component>
        )
    }

    return (
        <Text style={[styles[variant], style]} {...otherProps}>
            {props?.children}
        </Text>
    )
}

const webifySizes = (style) => {
    return Object.keys(style).reduce((acc, key) => {
        acc[key] = ['fontSize', 'lineHeight'].includes(key)
            ? `${style[key]}px`
            : style[key]
        return acc
    }, {})
}

const arrayToDict = (obj: Object) => {
    if (Array.isArray(obj)) {
        return obj.flat().reduce((acc, cur) => ({ ...acc, ...cur }), {})
    }

    return obj
}

// Make consistency between mobile and web by removing margins of semantic tags


const createStyles = (theme: BuilderTheme) => {
    
    const defaultStyle = {
        marginTop: 0,
        marginBottom: 0,
        fontFamily: theme?.typography?.fontFamily ?? 'Arial',
        fontWeight: theme?.typography?.fontWeight?.normal ?? '400',
        textAlign: "left" as TextAlign
    }

    return StyleSheet.create({
        xs: {
            ...defaultStyle,
            fontSize: PixelRatio.getFontScale() * (theme?.typography?.size?.xs?.fontSize ?? 12),
            lineHeight: PixelRatio.getFontScale() * (theme?.typography?.size?.xs?.lineHeight ?? 18),
        },
        sm: {
            ...defaultStyle,
            fontSize: PixelRatio.getFontScale() * (theme?.typography?.size?.sm?.fontSize ?? 14),
            lineHeight: PixelRatio.getFontScale() * (theme?.typography?.size?.sm?.lineHeight ?? 22),
        },
        base: {
            ...defaultStyle,
            fontSize: PixelRatio.getFontScale() * (theme?.typography?.size?.base?.fontSize ?? 16),
            lineHeight: PixelRatio.getFontScale() * (theme?.typography?.size?.base?.lineHeight ?? 24),
        },
        lg: {
            ...defaultStyle,
            fontSize: PixelRatio.getFontScale() * (theme?.typography?.size?.lg?.fontSize ?? 18),
            lineHeight: PixelRatio.getFontScale() * (theme?.typography?.size?.lg?.lineHeight ?? 26),
        },
        xl: {
            ...defaultStyle,
            fontSize: PixelRatio.getFontScale() * (theme?.typography?.size?.xl?.fontSize ?? 20),
            lineHeight: PixelRatio.getFontScale() * (theme?.typography?.size?.xl?.lineHeight ?? 28),
        },
        '2xl': {
            ...defaultStyle,
            fontSize: PixelRatio.getFontScale() * (theme?.typography?.size?.['2xl']?.fontSize ?? 24),
            lineHeight: PixelRatio.getFontScale() * (theme?.typography?.size?.['2xl']?.lineHeight ?? 32),
        },
        '3xl': {
            ...defaultStyle,
            fontSize: PixelRatio.getFontScale() * (theme?.typography?.size?.['3xl']?.fontSize ?? 30),
            lineHeight: PixelRatio.getFontScale() * (theme?.typography?.size?.['3xl']?.lineHeight ?? 40),
        },
        '4xl': {
            ...defaultStyle,
            fontSize: PixelRatio.getFontScale() * (theme?.typography?.size?.['4xl']?.fontSize ?? 36),
            lineHeight: PixelRatio.getFontScale() * (theme?.typography?.size?.['4xl']?.lineHeight ?? 44),
        },
        '5xl': {
            ...defaultStyle,
            fontSize: PixelRatio.getFontScale() * (theme?.typography?.size?.['5xl']?.fontSize ?? 48),
            lineHeight: PixelRatio.getFontScale() * (theme?.typography?.size?.['5xl']?.lineHeight ?? 56),
        },
    })
}

type TextAlign = "center" | "auto" | "left" | "right" | "justify"

export type TextVariant =
    | 'xs'
    | 'sm'
    | 'base'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'

export type SemanticComponent =
    | 'p'
    | 'span'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
